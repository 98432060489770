<!-- <div class="member-container mat-elevation-z8"> -->
<div class="member-loading-shade" *ngIf="data.loading$ | async">
    <mat-spinner></mat-spinner>
</div>
<mat-card class="member-find-container mat-elevation-z8">
    <form [formGroup]="findForm" (ngSubmit)="onFormSubmit()">
        <div fxLayout="wrap" fxLayout.xs="column" class="full-width">
            <div fxFlex="0 1 50%" fxFlex.xs="0 1 auto" fxLayoutAlign="start">
                <mat-radio-group formControlName="sexe">
                    <mat-radio-button value="" class="member-form-field">Tots</mat-radio-button>
                    <mat-radio-button value="D" class="member-form-field">Dona</mat-radio-button>
                    <mat-radio-button value="H" class="member-form-field">Home</mat-radio-button>
                </mat-radio-group>
            </div>
            <div fxFlex="0 1 50%" fxFlex.xs="0 1 auto" fxLayoutAlign="end" fxLayoutAlign.xs="start">
                <mat-slide-toggle formControlName="wodeleted" class="member-form-field">
                    Inclou els associats donats de baixa
                </mat-slide-toggle>
            </div>            
        </div>
        <div fxLayout="wrap" fxLayout.xs="column" class="full-width">
            <div fxFlex="0 1 20%" fxFlex.xs="0 1 auto">
                <mat-form-field appearance="fill" [floatLabel]="'always'">
                    <mat-label>Nom</mat-label>
                    <input #nom matInput formControlName="nom" placeholder="Nom" maxlength="255">
                    <mat-hint align="end">{{nom.value.length}} / 255</mat-hint>
                </mat-form-field>
            </div>
            <div fxFlex="0 1 40%" fxFlex.xs="0 1 auto">
                <mat-form-field appearance="fill" [floatLabel]="'always'">
                    <mat-label>Cognoms</mat-label>
                    <input #cognoms matInput formControlName="cognoms" placeholder="Cognoms" maxlength="255">
                    <mat-hint align="end">{{cognoms.value.length}} / 255</mat-hint>
                </mat-form-field>
            </div>
            <div fxFlex="0 1 20%" fxFlex.xs="0 1 auto">
                <mat-form-field appearance="fill" [floatLabel]="'always'">
                    <mat-label>
                        <mat-icon>account_circle</mat-icon> Nick
                    </mat-label>
                    <input #nick matInput formControlName="nick" placeholder="Nick" maxlength="32">
                    <mat-hint align="end">{{nick.value.length}} / 32</mat-hint>
                </mat-form-field>
            </div>
            <div fxFlex="0 1 20%" fxFlex.xs="0 1 auto">
                <mat-form-field appearance="fill" [floatLabel]="'always'">
                    <mat-label>
                        <mat-icon>alternate_email</mat-icon> eMail
                    </mat-label>
                    <input #email matInput placeholder="Ex. me@example.com" formControlName="email"
                        [errorStateMatcher]="matcher" maxlength="100">
                    <mat-hint align="end">{{email.value.length}} / 100</mat-hint>
                    <mat-error>
                        <span *ngIf="findForm.get('email').hasError('email') && findForm.get('email').touched">
                            El valor introduït no té el format correcte
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div fxLayout="wrap" fxLayout.xs="column" class="full-width">
            <div fxFlex="0 1 20%" fxFlex.xs="0 1 auto">
                <mat-form-field appearance="fill" [floatLabel]="'always'">
                    <mat-label>
                        <mat-icon>markunread_mailbox</mat-icon> Codi Postal
                    </mat-label>
                    <input #codiPostal matInput placeholder="Ex. 08001" formControlName="codiPostal" maxlength="32">
                    <mat-hint align="end">{{codiPostal.value.length}} / 32</mat-hint>
                </mat-form-field>
            </div>
            <div fxFlex="0 1 40%" fxFlex.xs="0 1 auto">
                <mat-form-field appearance="fill" [floatLabel]="'always'">
                    <mat-label>
                        <mat-icon>location_city</mat-icon> Població
                    </mat-label>
                    <input #poblacio matInput placeholder="Població" formControlName="poblacio" maxlength="255">
                    <mat-hint align="end">{{poblacio.value.length}} / 255</mat-hint>
                </mat-form-field>
            </div>
            <div fxFlex="0 1 20%" fxFlex.xs="0 1 auto">
                <mat-form-field appearance="fill" [floatLabel]="'always'">
                    <mat-label>NIF</mat-label>
                    <input #nif matInput placeholder="00000000T" formControlName="nif" pattern="[0-9]{8}[a-zA-Z]{1}"
                        maxlength="9">
                    <mat-hint align="end">{{nif.value.length}} / 9</mat-hint>
                    <mat-error>
                        <span *ngIf="!findForm.get('nif').valid && findForm.get('nif').touched">
                            Si us plau introdueix un NIF amb el format 00000000T
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="0 1 20%" fxFlex.xs="0 1 auto">
                <mat-form-field appearance="fill" [floatLabel]="'always'">
                    <mat-label>
                        <mat-icon>euro</mat-icon> Quota pagada al TPV
                    </mat-label>
                    <input matInput formControlName="quotaAlta" placeholder="Quota" pattern="[0-9]*(\.[0-9]*)?">
                    <mat-error *ngIf="!findForm.get('quotaAlta').valid">Si us plau introdueix un número</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="wrap" class="full-width">
            <div fxFlex="0 1 50%" fxLayoutAlign="start">
                <button mat-button mat-raised-button color="accent" matTooltip="Neteja els resultats" type="button"
                    (click)=" onClickReset($event)">
                    <mat-icon>clear</mat-icon> Neteja
                </button>
            </div>
            <div fxFlex="0 1 50%" fxLayoutAlign="end">
                <button #searchbutton mat-button mat-raised-button color="primary" matTooltip="Cerca" type="submit">
                    <mat-icon>search</mat-icon> Cerca
                </button>
            </div>
        </div>
    </form>

    <div class="member-list-container">
        <table mat-table [dataSource]="data" class="member-table" matSort matSortActive="cognoms"
            [matSortDirection]="sortDirection" matSortDisableClear>

            <ng-container matColumnDef="cognoms">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!sortable">Cognoms</th>
                <td mat-cell *matCellDef="let row">{{row.cognoms}}</td>
            </ng-container>

            <ng-container matColumnDef="nom">
                <th mat-header-cell *matHeaderCellDef>Nom</th>
                <td mat-cell *matCellDef="let row">{{row.nom}}</td>
            </ng-container>

            <ng-container matColumnDef="nick">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!sortable">nick</th>
                <td mat-cell *matCellDef="let row">{{row.nick}}</td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>eMail</th>
                <td mat-cell *matCellDef="let row">{{row.email}}</td>
            </ng-container>

            <ng-container matColumnDef="dataAlta">
                <th mat-header-cell *matHeaderCellDef>Data alta</th>
                <td mat-cell *matCellDef="let row">{{row.dataAlta | date: 'dd/MM/yyyy'}}</td>
            </ng-container>

            <ng-container matColumnDef="dataBaixa">
                <th mat-header-cell *matHeaderCellDef>Data baixa</th>
                <td mat-cell *matCellDef="let row">{{row.dataBaixa | date: 'dd/MM/yyyy'}}</td>
            </ng-container>

            <ng-container matColumnDef="activat">
                <th mat-header-cell *matHeaderCellDef>Estat</th>
                <td mat-cell *matCellDef="let row">
                    <mat-icon *ngIf="row.activat === 'false'" matTooltip="Associat donat de baixa"
                        class="member-deleted-icon">block</mat-icon>
                    <mat-icon *ngIf="row.activat === 'true' && row.observacions && row.observacions.length>0"
                        matTooltip="Associat amb avisos" class="member-warnings-icon">warning</mat-icon>
                    <mat-icon *ngIf="row.activat === 'true' && !row.observacions" matTooltip="Associat activat"
                        color="primary">directions_run</mat-icon>                        
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/member-details/', row.id]"
                class="do-action" [ngClass]="{'member-deleted': row.activat !== 'true'}"></tr>
        </table>
        <mat-paginator *ngIf="data.page$ | async as page" [length]="page.totalElements" [pageSize]="page.size" 
            [pageIndex]="page.number" showFirstLastButtons="true" (page)="data.fetch($event.pageIndex)" 
            [hidePageSize]="true"></mat-paginator>
    </div>
</mat-card>
<!-- </div> -->