<div class="login-container">
<form [formGroup]="loginForm" (ngSubmit)="login()">

  <mat-card class="mat-elevation-z8">
    <mat-card-content>
      <mat-form-field>
        <mat-label>Usuari</mat-label>
        <input matInput type="text" id="username" placeholder="Usuari" autocomplete="off" formControlName="username"
          required>
        <mat-error>
          <span *ngIf="!loginForm.get('username').valid && loginForm.get('username').touched">
            El camp usuari és obligatori
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Contrasenya</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" id="password" placeholder="Contrasenya" formControlName="password" required>
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" type="button">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>        
        <mat-error>
          <span *ngIf="!loginForm.get('password').valid && loginForm.get('password').touched">
            La contrasenya és obligatòria
          </span>
        </mat-error>
      </mat-form-field>

      <div class="form-group actions">
        <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid || loading">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Entra
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</form>
</div>