<h2 mat-dialog-title>Poblacions / Codis postals</h2>

<div mat-dialog-content>
    <div [formGroup]="searchForm">
        <mat-form-field class="full-width" appearance="fill">
            <input #city matInput placeholder="Població" formControlName="city" maxlength="255"
                (focus)="onFocus($event)">
            <mat-hint align="start" class="xs-nodisplay">Escriu com a mínim tres lletres del nom</mat-hint>
            <mat-hint align="end">{{city.value.length}} / 255</mat-hint>
        </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayout.xs="column" class="full-width">
        <div fxFlex="0 1 auto" class="pc-column">
            <mat-card>
                <mat-card-header>
                    <mat-card-subtitle class="xs-nodisplay">Població</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <mat-list>
                        <mat-list-item *ngFor="let city of potentialCities | async">
                            <p matLine><span class="do-action pc-data"
                                    (click)="searchPostalCodes(city)">{{city.nom}}</span></p>
                            <button mat-mini-fab color="primary" (click)="searchPostalCodes(city)">
                                <mat-icon> mail</mat-icon>
                            </button>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
            </mat-card>
        </div>

        <div fxFlex="0 1 auto" class="pc-column">
            <mat-card>
                <mat-card-content>
                    <mat-card-header>
                        <mat-card-subtitle class="xs-nodisplay">Codis Postals</mat-card-subtitle>
                    </mat-card-header>
                    <mat-selection-list [(ngModel)]="postalCode" [multiple]="false">
                        <div fxLayout="row wrap">
                            <ng-container *ngFor="let pc of postalCodesList">
                                <mat-list-option [value]="pc.valor" fxFlex="0 1 33.3%" fxFlex.gt-md="0 1 20%">
                                    {{pc.valor}}
                                </mat-list-option>
                            </ng-container>
                        </div>
                    </mat-selection-list>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-raised-button color="accent" (click)="onDismiss()">Cancel.la</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Ok</button>
</div>
