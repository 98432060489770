<!-- <div class="member-container mat-elevation-z8"> -->
<div class="member-loading-shade" *ngIf="isLoadingResults">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>
<!-- <div>
        <a mat-mini-fab color="primary" matTooltip="Torna al llistat" [routerLink]="['/member-find']" class="home">
          <mat-icon>home</mat-icon>
        </a>
        <span class="mat-title">Detalls d'un associat</span>
      </div> -->
<mat-card class="member-details-container mat-elevation-z8"
  [ngClass]="{'member-deleted-bg' : member.activat === false}">
  <mat-card-header>
    <div mat-card-avatar
      [ngClass]="{'member-deleted' : member.activat === false,'member-child' : member.activat === true}">
      <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita" (click)="onClickEditField('sexe')">edit
      </mat-icon>
    </div>
    <mat-card-title>{{member.nick}} <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita"
        (click)="onClickEditField('nick')">edit</mat-icon>
    </mat-card-title>
    <mat-card-subtitle>{{member.cognoms}}, {{member.nom}} <mat-icon *ngIf="editMode" class="editable-field"
        matTooltip="Edita" (click)="onClickEditField('nom')">edit</mat-icon>
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <mat-card class="mat-elevation-z4 full-height margin-around">
      <div fxLayout="wrap" fxLayout.xs="column" class="full-width">
        <div fxFlex="0 1 25%" fxFlex.xs="0 1 auto">
          <dl class="width-95">
            <dt>Activat:</dt>
            <dd>
              <mat-slide-toggle [(ngModel)]="member.activat" [disabled]="!editMode">
              </mat-slide-toggle> No / Sí
            </dd>
            <dt>Mare/Pare/Tutor: <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita"
                (click)="onClickEditField('responsable')">edit</mat-icon>
            </dt>
            <dd>{{member.responsable}}</dd>
          </dl>
        </div>

        <div fxFlex="0 1 25%" fxFlex.xs="0 1 auto">
          <dl class="width-95">
            <dt>eMail: <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita"
                (click)="onClickEditField('email')">edit</mat-icon>
            </dt>
            <dd>{{member.email | default:'---'}}</dd>
            <dt>DNI: <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita"
                (click)="onClickEditField('dni')">edit</mat-icon>
            </dt>
            <dd>{{member.dni | default:'---'}}</dd>
          </dl>
        </div>

        <div fxFlex="0 1 25%" fxFlex.xs="0 1 auto">
          <dl class="width-95">
            <dt>Data de Naixement: <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita"
                (click)="onClickEditField('dataNaixement')">edit</mat-icon>
            </dt>
            <dd>{{member.dataNaixement | date: 'dd/MM/yyyy' | default:'---'}}</dd>
            <dt>Data d'alta: <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita"
                (click)="onClickEditField('dataAlta')">edit</mat-icon>
            </dt>
            <dd>{{member.dataAlta | date: 'dd/MM/yyyy' | default:'---'}}</dd>
          </dl>
        </div>

        <div fxFlex="0 1 25%" fxFlex.xs="0 1 auto">
          <dl class="width-95">
            <dt>Data de baixa: <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita"
                (click)="onClickEditField('dataBaixa')">edit</mat-icon>
            </dt>
            <dd>{{member.dataBaixa | date: 'dd/MM/yyyy' | default:'---'}}</dd>
            <dt>Observacions: <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita"
                (click)="onClickEditField('observacions')">edit</mat-icon>
            </dt>
            <dd>{{member.observacions | default:'---'}}</dd>
          </dl>
        </div>
      </div>
    </mat-card>
  </mat-card-content>

  <mat-card-actions align="end">
    <div fxLayout="wrap" class="full-width">    
      <div fxFlex="0 1 100%" fxLayoutAlign="end">
        <a mat-flat-button color="accent" (click)="onClickBack()">
          <mat-icon>arrow_back</mat-icon> Enrera
        </a>        
        <button *ngIf="!editMode" [disabled]="!authService.hasAnyRole([roles.ADMIN,roles.SECRETARIA,roles.JUNTA])"
          mat-flat-button color="primary" (click)="onClickEdit($event)" type="button">
          <mat-icon>edit</mat-icon> Edita
        </button>
        <button *ngIf="editMode" [disabled]="!authService.hasAnyRole([roles.ADMIN,roles.SECRETARIA,roles.JUNTA])"
          matTooltip="Cancel·la" mat-flat-button color="accent" (click)="onClickEditCancel($event)" type="button">
          <mat-icon>cancel</mat-icon> Cancel·la
        </button>
        <button *ngIf="editMode" [disabled]="!authService.hasAnyRole([roles.ADMIN,roles.SECRETARIA])"
          matTooltip="Desa els canvis" mat-flat-button color="primary" (click)="onClickEditSave($event)" type="button">
          <mat-icon>save</mat-icon> Desa
        </button>
      </div>
    </div>
  </mat-card-actions>
</mat-card>
<!-- </div> -->