<div class="window-container">
  <div class="header">
    <div fxLayout="wrap" class="full-width">
      <div fxFlex="0 1 50%">
        <img src="../assets/images/logo.png" class="logo">
      </div>
      <div fxFlex="0 1 50%" fxLayoutAlign="end">
        <button mat-button mat-raised-button class="logout" color="accent" (click)="snav.close();logout()"
          *ngIf="logged">Desconnecta</button>
      </div>
    </div>
    <mat-toolbar class="content-toolbar" *ngIf="logged">
      <button mat-icon-button (click)="snav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      {{title}}
    </mat-toolbar>
  </div>

  <div class="content-container" [class.content-is-mobile]="mobileQuery.matches">
    <mat-sidenav-container class="content-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 5 : 0">
      <!-- <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
        fixedTopGap="56"> -->
      <mat-sidenav #snav [mode]="'over'" [fixedInViewport]="false">
        <mat-nav-list dense>
          <a mat-list-item routerLink="/member-find" (click)="snav.toggle()" 
          *ngIf="authService.hasAnyRole([roles.ADMIN,roles.SECRETARIA,roles.JUNTA])">Localitza un associat</a>
          <a mat-list-item routerLink="/member-add" (click)="snav.toggle()"
          *ngIf="authService.hasAnyRole([roles.ADMIN,roles.SECRETARIA,roles.JUNTA])">Alta d'un nou associat</a>
          <a mat-list-item routerLink="/member-children-list" (click)="snav.toggle()"
          *ngIf="authService.hasAnyRole([roles.ADMIN,roles.SECRETARIA,roles.JUNTA,roles.ORGANITZADORA])">Llista d'associats infantils</a>
          <a mat-list-item routerLink="/member-child-add" (click)="snav.toggle()"
          *ngIf="authService.hasAnyRole([roles.ADMIN,roles.SECRETARIA,roles.JUNTA])">Alta d'un nou associat infantil</a>          
          <a mat-list-item routerLink="/members-report" (click)="snav.toggle()"
          *ngIf="authService.hasAnyRole([roles.ADMIN,roles.SECRETARIA,roles.JUNTA])">Descarrega un fitxer predefinit</a>
          <a mat-list-item routerLink="/members-export" (click)="snav.toggle()"
          *ngIf="authService.hasAnyRole([roles.ADMIN,roles.SECRETARIA,roles.JUNTA])">Genera i descarrega el teu fitxer</a>
        </mat-nav-list>
      </mat-sidenav>

      <mat-sidenav-content>
        <div class="content">
          <app-alert></app-alert>
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>

  <div class="footer">
    <span class="mat-small">Associació Esportiva Corredors.cat © 2020 Tots els drets reservats</span>
  </div>
</div>