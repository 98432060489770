<!-- <div class="member-container mat-elevation-z8"> -->
  <div class="member-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>
  <!-- <div>
    <a mat-mini-fab color="primary" matTooltip="Torna al llistat" [routerLink]="['/member-find']" class="home">
      <mat-icon>home</mat-icon>
    </a>
    <span class="mat-title">Detalls d'un associat</span>
  </div> -->
  <mat-card class="member-details-container mat-elevation-z8" [ngClass]="{'member-deleted-bg' : member.activat === false}">
    <mat-card-header>
      <div mat-card-avatar [ngClass]="{'member-deleted' : member.activat === false,
      'member-male' : member.activat === true && member.sexe === 'H', 
      'member-female' : member.activat === true && member.sexe === 'D'}">
      <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita" (click)="onClickEditField('sexe')">edit</mat-icon>      
      </div>
      <mat-card-title>{{member.nick}} <mat-icon 
        *ngIf="editMode" class="editable-field" matTooltip="Edita" (click)="onClickEditField('nick')">edit</mat-icon></mat-card-title>
      <mat-card-subtitle>{{member.cognoms}}, {{member.nom}} <mat-icon 
        *ngIf="editMode" class="editable-field" matTooltip="Edita" (click)="onClickEditField('nom')">edit</mat-icon></mat-card-subtitle>
    </mat-card-header>

    <ng-template #basicdata>
      <dl class="width-95">
        <dt>Activat:</dt>
        <dd><mat-slide-toggle [(ngModel)]="member.activat" [disabled]="member.activat || !editMode"></mat-slide-toggle> No / Sí</dd>           
        <dt>eMail: <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita" (click)="onClickEditField('email')">edit</mat-icon></dt>
        <dd>{{member.email}}</dd>
        <dt>NIF: <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita" (click)="onClickEditField('nif')">edit</mat-icon></dt>
        <dd>{{member.nif | default:'---'}}</dd>
        <dt>IBAN: <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita" 
          (click)="onClickEditField('iban')">edit</mat-icon></dt>
        <dd>{{member.iban | default:'---'}}</dd>        
      </dl>
    </ng-template>

    <ng-template #contactdata>
      <dl class="width-95">
        <dt>Telèfon: <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita" (click)="onClickEditField('telefon')">edit</mat-icon></dt>
        <dd>{{member.telefon | default:'---'}}</dd>
        <dt>Adreça: <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita" (click)="onClickEditField('adreca')">edit</mat-icon></dt>
        <dd>{{member.adreca | default:'---'}}</dd>
        <dt>Codi postal: <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita" (click)="onClickEditField('codiPostal')">edit</mat-icon></dt>
        <dd>{{member.codiPostal | default:'---'}}</dd>
        <dt>Població: <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita" (click)="onClickEditField('poblacio')">edit</mat-icon></dt>
        <dd>{{member.poblacio | default:'---'}}</dd>
      </dl>
    </ng-template>

    <ng-template #otherdata>
      <dl class="width-95">
        <dt>Quota pagada al TPV: <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita" 
          (click)="onClickEditField('quotaAlta')">edit</mat-icon></dt>
        <dd>{{member.quotaAlta | default:'---'}} €</dd>      
        <dt>Data d'alta: <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita" (click)="onClickEditField('dataAlta')">edit</mat-icon></dt>
        <dd>{{member.dataAlta | date: 'dd/MM/yyyy' | default:'---'}}</dd>
        <dt>Data de baixa: <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita" (click)="onClickEditField('dataBaixa')">edit</mat-icon></dt>
        <dd>{{member.dataBaixa | date: 'dd/MM/yyyy' | default:'---'}}</dd>        
        <dt>Observacions: <mat-icon *ngIf="editMode" class="editable-field" matTooltip="Edita" (click)="onClickEditField('observacions')">edit</mat-icon></dt>
        <dd>{{member.observacions | default:'---'}}</dd>
      </dl>
    </ng-template>

    <ng-template #bigScreen>
      <mat-card-content>
        <div fxLayout="wrap" fxLayout.xs="column" class="full-width">
          <div fxFlex="0 1 33%">
            <mat-card class="mat-elevation-z4 full-height margin-around">
              <mat-card-header>
                <div mat-card-avatar class="basic-header-image"></div>
                <span class="mat-tab-label">Bàsica</span>
              </mat-card-header>
              <mat-card-content>
                <ng-container *ngTemplateOutlet="basicdata"></ng-container>
              </mat-card-content>
            </mat-card>
          </div>

          <div fxFlex="0 1 33%">
            <mat-card class="mat-elevation-z4 full-height margin-around">
              <mat-card-header>
                <div mat-card-avatar class="contact-header-image"></div>
                <span class="mat-tab-label">Contacte</span>
              </mat-card-header>
              <mat-card-content>
                <ng-container *ngTemplateOutlet="contactdata"></ng-container>
              </mat-card-content>
            </mat-card>
          </div>

          <div fxFlex="0 1 33%">
            <mat-card class="mat-elevation-z4 full-height margin-around">
              <mat-card-header>
                <div mat-card-avatar class="other-header-image"></div>
                <span class="mat-tab-label">Altra</span>
              </mat-card-header>
              <mat-card-content>
                <ng-container *ngTemplateOutlet="otherdata"></ng-container>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </mat-card-content>
    </ng-template>

    <ng-template [ngIf]="smallScreen" [ngIfElse]="bigScreen">
      <mat-card-content (panleft)="onPanLeft($event)" (panright)="onPanRight($event)">
        <mat-tab-group #usertabs>
          <mat-tab label="Bàsica">
            <ng-container *ngTemplateOutlet="basicdata"></ng-container>
          </mat-tab>

          <mat-tab label="Contacte" class="full-width">
            <ng-container *ngTemplateOutlet="contactdata"></ng-container>
          </mat-tab>

          <mat-tab label="Altra" class="full-width">
            <ng-container *ngTemplateOutlet="otherdata"></ng-container>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </ng-template>

    <mat-card-actions align="end">
      <div fxLayout="wrap" class="full-width">
        <div fxFlex="0 1 100%" fxLayoutAlign="end">
          <a mat-flat-button color="accent" (click)="onClickBack()">
            <mat-icon>arrow_back</mat-icon> Enrera
          </a>          
          <a *ngIf="!editMode && member.activat===true" [disabled]="!authService.hasAnyRole([roles.ADMIN,roles.SECRETARIA])"
          mat-flat-button color="accent" (click)="onClickUnregisterMember(member.id)">
            <mat-icon>delete</mat-icon> Baixa
          </a>
          <button *ngIf="!editMode" [disabled]="!authService.hasAnyRole([roles.ADMIN,roles.SECRETARIA,roles.JUNTA])" 
          mat-flat-button color="primary" (click)="onClickEdit($event)" type="button">
            <mat-icon>edit</mat-icon> Edita
          </button>
          <button *ngIf="editMode" [disabled]="!authService.hasAnyRole([roles.ADMIN,roles.SECRETARIA,roles.JUNTA])"
          matTooltip="Cancel·la" mat-flat-button color="accent" (click)="onClickEditCancel($event)" type="button">
            <mat-icon>cancel</mat-icon> Cancel·la
          </button>
          <button *ngIf="editMode" [disabled]="!authService.hasAnyRole([roles.ADMIN,roles.SECRETARIA])"
          matTooltip="Desa els canvis" mat-flat-button color="primary" (click)="onClickEditSave($event)" type="button">
            <mat-icon>save</mat-icon> Desa
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
<!-- </div> -->