<!-- <div class="member-container mat-elevation-z8"> -->
<!-- <div>
    <a mat-flat-button color="primary" [routerLink]="['/member-add']">
      <mat-icon>add</mat-icon> Alta
    </a>
    <a mat-flat-button color="primary" [routerLink]="['/members-report']">
      <mat-icon>save_alt</mat-icon> Fitxers
    </a>
    <a mat-flat-button color="primary" [routerLink]="['/members-export']">
      <mat-icon>settings</mat-icon>
    </a>
  </div> -->
<mat-card class="member-list-container mat-elevation-z8">
  
  <div fxLayout="wrap" fxLayout.xs="column" class="toolbar">
    <div fxFlex="0 1 80%" fxFlex.xs="0 1 auto" fxLayoutAlign="start">
    <mat-form-field class="search full-width" [floatLabel]="'always'">
      <mat-icon matPrefix>search</mat-icon>
      <mat-label>Cerca</mat-label>
      <input #search (input)="inputChanged({search: search.value})" type="text" matInput 
      placeholder="Cerca pel nom,cognoms,nick o nick dels pares" [formControl]="searchControl">
    </mat-form-field>
  </div>
  <div fxFlex="0 1 20%" fxFlex.xs="0 1 auto" fxLayoutAlign="end" fxLayoutAlign.xs="start">
    <mat-slide-toggle [(ngModel)]="wdeleted" (change)="inputChanged({search: search.value})" class="member-form-field">
        Inclou els donats de baixa
    </mat-slide-toggle>
</div>    
  </div>
  
  <div class="member-table-container">
    <table mat-table [dataSource]="data" class="member-table" matSort [matSortActive]="sortField" [matSortDirection]="sortDirection"
    matSortDisableClear>

      <ng-container matColumnDef="cognoms">
        <th mat-header-cell *matHeaderCellDef>Cognoms</th>
        <td mat-cell *matCellDef="let row">{{row.cognoms}}</td>
      </ng-container>

      <ng-container matColumnDef="nom">
        <th mat-header-cell *matHeaderCellDef>Nom</th>
        <td mat-cell *matCellDef="let row">{{row.nom}}</td>
      </ng-container>

      <ng-container matColumnDef="nick">
        <th mat-header-cell *matHeaderCellDef>nick</th>
        <td mat-cell *matCellDef="let row">{{row.nick}}</td>
      </ng-container>

      <ng-container matColumnDef="responsable">
        <th mat-header-cell *matHeaderCellDef>Mare/Pare/Tutor</th>
        <td mat-cell *matCellDef="let row">{{row.responsable}}</td>
      </ng-container>

      <ng-container matColumnDef="dataNaixement">
        <th mat-header-cell *matHeaderCellDef>Any Naixement</th>
        <td mat-cell *matCellDef="let row">{{row.dataNaixement | date: 'yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="infantil">
        <th mat-header-cell *matHeaderCellDef>Edat</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon *ngIf="age(row.dataNaixement) && age(row.dataNaixement)<18" 
          [matTooltip]="age(row.dataNaixement)" color="primary">child_care</mat-icon>
          <mat-icon *ngIf="age(row.dataNaixement) && age(row.dataNaixement)>=18" 
          [matTooltip]="age(row.dataNaixement)" color="primary">school</mat-icon>          
        </td>
      </ng-container>

      <ng-container matColumnDef="activat">
        <th mat-header-cell *matHeaderCellDef>Estat</th>
        <td mat-cell *matCellDef="let row">
            <mat-icon *ngIf="row.activat === true" matTooltip="Associat activat"
                color="primary">directions_run</mat-icon>
            <mat-icon *ngIf="row.activat === false" matTooltip="Associat donat de baixa"
                class="member-deleted-icon">block</mat-icon>                
        </td>
    </ng-container>

      <ng-container matColumnDef="disclaimer">
        <td mat-footer-cell *matFooterCellDef colspan="3" class="footer-row">
          Fer click a sobre un registre per veure'n els detalls
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/member-child-details/', row.id]"
        class="do-action" [ngClass]="{'member-deleted': row.activat !== true}"></tr>
      <!-- tr mat-footer-row *matFooterRowDef="['disclaimer']"></tr -->
    </table>
  </div>
  <mat-paginator #paginator *ngIf="data.page$ | async as page" [length]="page.totalElements" [pageSize]="page.size"
    [pageIndex]="page.number" [hidePageSize]="true" showFirstLastButtons="true" (page)="data.fetch($event.pageIndex)">
  </mat-paginator>
</mat-card>
<!-- </div> -->