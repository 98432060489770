<h2 mat-dialog-title>Edita</h2>

<div mat-dialog-content>
  <form [formGroup]="memberForm">

    <mat-form-field *ngIf="model.name === 'nom'" class="full-width">
      <mat-label>Nom</mat-label>
      <input #nom matInput placeholder="Nom" formControlName="nom" [errorStateMatcher]="matcher" required
        maxlength="255">
      <mat-hint align="end">{{nom.value.length}} / 255</mat-hint>
      <mat-error>
        <span *ngIf="memberForm.get('nom').dirty && !memberForm.get('nom').valid">Si us plau introdueix el
          nom</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="model.name === 'nom'" class="full-width">
      <mat-label>Cognoms</mat-label>
      <input #cognoms matInput placeholder="Cognoms" formControlName="cognoms" [errorStateMatcher]="matcher"
        required maxlength="255">
      <mat-hint align="end">{{cognoms.value.length}} / 255</mat-hint>
      <mat-error>
        <span *ngIf="memberForm.get('cognoms').dirty && !memberForm.get('cognoms').valid">Si us plau introdueix els
          cognoms</span>
      </mat-error>
    </mat-form-field>

    <mat-radio-group formControlName="sexe" *ngIf="model.name === 'sexe'">
      <mat-radio-button value="D"><span class="wrap-mat-radio-label">Dona</span></mat-radio-button>
      <mat-radio-button value="H"><span class="wrap-mat-radio-label">Home</span></mat-radio-button>
    </mat-radio-group>

    <mat-form-field *ngIf="model.name === 'nick'" class="full-width">
      <mat-label>Nick</mat-label>
      <input #nick matInput placeholder="Nick" formControlName="nick" [errorStateMatcher]="matcher" required
        maxlength="32">
        <mat-icon matSuffix>account_circle</mat-icon>
      <mat-hint align="end">{{nick.value.length}} / 32</mat-hint>
      <mat-error>
        <span *ngIf="memberForm.get('nick').dirty && memberForm.get('nick').hasError('required')">Si us plau
          introdueix el nick</span>
        <span *ngIf="memberForm.get('nick').dirty && memberForm.get('nick').hasError('api')">
          {{memberForm.get('nick').getError('api')}}</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="model.name === 'email'" class="full-width">
      <mat-label>eMail</mat-label>
      <input #email matInput placeholder="Ex. me@example.com" formControlName="email" [errorStateMatcher]="matcher"
        required maxlength="100">
        <mat-icon matSuffix>alternate_email</mat-icon>
      <mat-hint align="end">{{email.value.length}} / 100</mat-hint>
      <mat-error>
        <span *ngIf="memberForm.get('email').dirty && memberForm.get('email').hasError('required')">
          Si us plau introdueix una adreça de correu vàlida
        </span>
        <span *ngIf="memberForm.get('email').dirty && memberForm.get('email').hasError('email')">
          El valor introduït no té el format correcte
        </span>
        <span *ngIf="memberForm.get('email').dirty && memberForm.get('email').hasError('api')">
          {{memberForm.get('email').getError('api')}}</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="model.name === 'nif'" class="full-width">
      <mat-label>NIF</mat-label>
      <input #nif matInput placeholder="00000000T" formControlName="nif" pattern="[0-9]{8}[a-zA-Z]{1}"
        maxlength="9">
      <mat-hint align="end">{{nif.value.length}} / 9</mat-hint>
      <mat-error>
        <span *ngIf="memberForm.get('nif').dirty && memberForm.get('nif').hasError('pattern')">
          Si us plau introdueix un NIF amb el format 00000000T
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="model.name === 'iban'" class="full-width">
      <mat-label>IBAN</mat-label>
      <input #iban matInput placeholder="ES00 1111 2222 3333 4444 5555" formControlName="iban"
        pattern="[a-zA-Z]{2}[0-9]{2}[\s]*[0-9]{4}[\s]*[0-9]{4}[\s]*[0-9]{4}[\s]*[0-9]{4}[\s]*[0-9]{4}"
        maxlength="32">
        <mat-icon matSuffix>account_balance</mat-icon>
      <mat-hint align="end">{{iban.value.length}} / 32</mat-hint>
      <mat-error>
        <span *ngIf="memberForm.get('iban').dirty && memberForm.get('iban').hasError('pattern')">
          Si us plau introdueix un IBAN amb el format 'ES00 1111 2222 3333 4444 5555'
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="model.name === 'quotaAlta'" class="full-width">
      <mat-label>Quota pagada al TPV</mat-label>
      <input matInput placeholder="Quota" formControlName="quotaAlta" pattern="[0-9]*(\.[0-9]*)?">
      <mat-icon matSuffix>euro</mat-icon>
      <mat-error *ngIf="!memberForm.get('quotaAlta').valid">Si us plau introdueix un número</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="model.name === 'dataAlta'" class="full-width">
      <mat-label>Data del pagament</mat-label>
      <input #dataAlta matInput [matDatepicker]="picker" placeholder="dd/mm/yyyy" formControlName="dataAlta"
        maxlength="10">
      <mat-hint align="start">Selecciona o escriu dia o dia/mes</mat-hint>
      <mat-hint align="end">{{dataAlta.value.length}} / 10</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error>
        <span *ngIf="memberForm.get('dataAlta').dirty && !memberForm.get('dataAlta').valid">
          Si us plau introdueix una data vàlida amb el format 'dd/mm/yyyy'
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="model.name === 'dataBaixa'" class="full-width">
      <mat-label>Data de la baixa</mat-label>
      <input #dataBaixa matInput [matDatepicker]="picker2" placeholder="dd/mm/yyyy" formControlName="dataBaixa"
        maxlength="10">
      <mat-hint align="start">Selecciona o escriu dia o dia/mes</mat-hint>
      <mat-hint align="end">{{dataBaixa.value.length}} / 10</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
      <mat-error>
        <span *ngIf="memberForm.get('dataBaixa').dirty && !memberForm.get('dataBaixa').valid">
          Si us plau introdueix una data vàlida amb el format 'dd/mm/yyyy'
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="model.name === 'telefon'" class="full-width">
      <mat-label>Telèfon</mat-label>
      <input #telefon matInput formControlName="telefon" placeholder="Ex. 655123456" maxlength="32">
      <mat-icon matSuffix>phone</mat-icon>
      <mat-hint align="end">{{telefon.value.length}} / 32</mat-hint>
    </mat-form-field>

    <mat-form-field *ngIf="model.name === 'adreca'" class="full-width">
      <mat-label>Adreça</mat-label>
      <input #adreca matInput placeholder="Adreça" formControlName="adreca" maxlength="255">
      <mat-icon matSuffix>mail</mat-icon>
      <mat-hint align="end">{{adreca.value.length}} / 255</mat-hint>
    </mat-form-field>

    <mat-form-field *ngIf="model.name === 'codiPostal'" class="full-width">
      <mat-label>Codi Postal</mat-label>
      <input #codiPostal matInput placeholder="Ex. 08001" formControlName="codiPostal" maxlength="32">
      <mat-hint align="end">{{codiPostal.value.length}} / 32</mat-hint>
    </mat-form-field>

    <mat-form-field *ngIf="model.name === 'poblacio'" class="full-width">
      <mat-label>Població</mat-label>
      <input #poblacio matInput placeholder="Població" formControlName="poblacio" maxlength="255">
        <mat-icon matSuffix>location_city</mat-icon>
      <mat-hint align="end">{{poblacio.value.length}} / 255</mat-hint>
    </mat-form-field>

    <mat-form-field *ngIf="model.name === 'observacions'" class="full-width">
      <mat-label>Observacions</mat-label>
      <textarea #observacions matInput rows="12" formControlName="observacions" placeholder="Observacions"
        maxlength="1024"></textarea>
      <mat-hint align="end">{{observacions.value.length}} / 1024</mat-hint>
    </mat-form-field>

  </form>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="accent" (click)="onDismiss()">Cancel·la</button>
  <button mat-raised-button [disabled]="memberForm.dirty && !memberForm.valid" color="primary" (click)="onConfirm()">Ok</button>
</div>