<!-- <div class="member-container mat-elevation-z8"> -->
<div class="member-loading-shade" *ngIf="isLoadingResults">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>
<!-- <div>
    <a mat-mini-fab color="primary" matTooltip="Torna al llistat" [routerLink]="['/member-find']" class="home">
      <mat-icon>home</mat-icon>
    </a>
    <span class="mat-title">Alta d'un nou associat</span>
  </div> -->
<mat-card class="member-add-container mat-elevation-z8">
  <form [formGroup]="memberForm" (ngSubmit)="onFormSubmit()">

    <ng-template #step1>
      <mat-card class="mat-elevation-z4 form-card">
        <mat-radio-group formControlName="sexe">
          <mat-radio-button value="D"><span class="wrap-mat-radio-label">Dona</span></mat-radio-button>
          <mat-radio-button value="H"><span class="wrap-mat-radio-label">Home</span></mat-radio-button>
        </mat-radio-group>
        <mat-form-field class="full-width">
          <mat-label>Nom</mat-label>
          <input #nom matInput placeholder="Nom" formControlName="nom" [errorStateMatcher]="matcher" required
            maxlength="255">
          <mat-hint align="end">{{nom.value.length}} / 255</mat-hint>
          <mat-error>
            <span *ngIf="!memberForm.get('nom').valid && memberForm.get('nom').touched">Si us plau introdueix el
              nom</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Cognoms</mat-label>
          <input #cognoms matInput placeholder="Cognoms" formControlName="cognoms" [errorStateMatcher]="matcher"
            required maxlength="255">
          <mat-hint align="end">{{cognoms.value.length}} / 255</mat-hint>
          <mat-error>
            <span *ngIf="!memberForm.get('cognoms').valid && memberForm.get('cognoms').touched">Si us plau introdueix
              els
              cognoms</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Nick</mat-label>
          <input #nick matInput placeholder="Nick" formControlName="nick" [errorStateMatcher]="matcher" required
            maxlength="32">
          <mat-icon matSuffix>account_circle</mat-icon>
          <mat-hint align="end">{{nick.value.length}} / 32</mat-hint>
          <mat-error>
            <span *ngIf="memberForm.get('nick').hasError('required') && memberForm.get('nick').touched">Si us plau
              introdueix el nick</span>
            <span *ngIf="memberForm.get('nick').hasError('api') && memberForm.get('nick').touched">
              {{memberForm.get('nick').getError('api')}}</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>eMail</mat-label>
          <input #email matInput placeholder="Ex. me@example.com" formControlName="email" [errorStateMatcher]="matcher"
            required maxlength="100">
          <mat-icon matSuffix>alternate_email</mat-icon>
          <mat-hint align="end">{{email.value.length}} / 100</mat-hint>
          <mat-error>
            <span *ngIf="memberForm.get('email').hasError('required') && memberForm.get('email').touched">
              Si us plau introdueix una adreça de correu vàlida
            </span>
            <span *ngIf="memberForm.get('email').hasError('email') && memberForm.get('email').touched">
              El valor introduït no té el format correcte
            </span>
            <span *ngIf="memberForm.get('email').hasError('api') && memberForm.get('email').touched">
              {{memberForm.get('email').getError('api')}}</span>
          </mat-error>
        </mat-form-field>
      </mat-card>
    </ng-template>

    <ng-template #step2>
      <mat-card class="mat-elevation-z4 form-card">
        <mat-form-field class="full-width">
          <mat-label>NIF</mat-label>
          <input #nif matInput placeholder="00000000T" formControlName="nif" pattern="[0-9]{8}[a-zA-Z]{1}"
            maxlength="9">
          <mat-hint align="end">{{nif.value.length}} / 9</mat-hint>
          <mat-error>
            <span *ngIf="!memberForm.get('nif').valid && memberForm.get('nif').touched">
              Si us plau introdueix un NIF amb el format 00000000T
            </span>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>IBAN</mat-label>
          <input #iban matInput placeholder="ES00 1111 2222 3333 4444 5555" formControlName="iban"
            pattern="[a-zA-Z]{2}[0-9]{2}[\s]*[0-9]{4}[\s]*[0-9]{4}[\s]*[0-9]{4}[\s]*[0-9]{4}[\s]*[0-9]{4}"
            maxlength="32">
          <mat-icon matSuffix>account_balance</mat-icon>
          <mat-hint align="end">{{iban.value.length}} / 32</mat-hint>
          <mat-error>
            <span *ngIf="!memberForm.get('iban').valid && memberForm.get('iban').touched">
              Si us plau introdueix un IBAN amb el format 'ES00 1111 2222 3333 4444 5555'
            </span>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Quota pagada al TPV</mat-label>
          <input matInput placeholder="Quota" formControlName="quotaAlta" pattern="[0-9]*(\.[0-9]*)?">
          <mat-icon matSuffix>euro</mat-icon>
          <mat-error *ngIf="!memberForm.get('quotaAlta').valid">Si us plau introdueix un número</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Data del pagament</mat-label>
          <input #dataAlta matInput [matDatepicker]="picker" placeholder="dd/mm/yyyy" formControlName="dataAlta"
            maxlength="10">
          <mat-hint align="start">Selecciona o escriu dia o dia/mes</mat-hint>
          <mat-hint align="end">{{dataAlta.value.length}} / 10</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error>
            <span *ngIf="!memberForm.get('dataAlta').valid && memberForm.get('dataAlta').touched">
              Si us plau introdueix una data vàlida amb el format 'dd/mm/yyyy'
            </span>
          </mat-error>
        </mat-form-field>       
      </mat-card>
    </ng-template>

    <ng-template #step3>
      <mat-card class="mat-elevation-z4 form-card">
        <mat-form-field class="full-width">
          <mat-label>Telèfon</mat-label>
          <input #telefon matInput formControlName="telefon" placeholder="Ex. 655123456" maxlength="32">
          <mat-icon matSuffix>phone</mat-icon>
          <mat-hint align="end">{{telefon.value.length}} / 32</mat-hint>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Adreça</mat-label>
          <input #adreca matInput placeholder="Adreça" formControlName="adreca" maxlength="255">
          <mat-icon matSuffix>mail</mat-icon>
          <mat-hint align="end">{{adreca.value.length}} / 255</mat-hint>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Codi Postal</mat-label>
          <input #codiPostal matInput [matAutocomplete]="auto1" placeholder="Ex. 08001" formControlName="codiPostal"
            maxlength="32" (change)="onCodiPostalChange($event)">
          <mat-icon matSuffix matTooltip="Asistent poblacions/codis postals" (click)="openCitiesAndPostalCodesDialog()"
            class="do-action icon-as-button">list</mat-icon>
          <mat-hint align="end">{{codiPostal.value.length}} / 32</mat-hint>
          <mat-autocomplete #auto1="matAutocomplete">
            <mat-option *ngFor="let pc of filteredCodes | async" [value]="pc.valor" [id]="pc.internalId">
              {{pc.valor}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Població</mat-label>
          <input #poblacio matInput [matAutocomplete]="auto2" placeholder="Població" formControlName="poblacio"
            maxlength="255" (focus)="onFocusPoblacio($event)">
          <mat-icon matSuffix>location_city</mat-icon>
          <mat-hint align="start">Escriu com a mínim tres lletres del nom</mat-hint>
          <mat-hint align="end">{{poblacio.value.length}} / 255</mat-hint>
          <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="onPoblacioSelected($event)">
            <mat-option *ngFor="let city of filteredCities | async" [value]="city.nom" [id]="city.codi">
              {{city.nom}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </mat-card>
    </ng-template>

    <ng-template #step4>
      <mat-card class="mat-elevation-z4 form-card">
        <mat-form-field class="full-width">
          <mat-label>Observacions</mat-label>
          <textarea #observacions matInput rows="12" formControlName="observacions" placeholder="Observacions"
            maxlength="1024"></textarea>
          <mat-hint align="end">{{observacions.value.length}} / 1024</mat-hint>
        </mat-form-field>
      </mat-card>
    </ng-template>

    <ng-template #bigScreen>
      <mat-horizontal-stepper [linear]="isLinear" labelPosition="right" #stepper>
        <mat-step [stepControl]="memberForm">
          <ng-template matStepLabel>Dades bàsiques</ng-template>
          <ng-container *ngTemplateOutlet="step1"></ng-container>
          <div class="button-row">
            <button mat-button mat-raised-button color="primary" matStepperNext type="button"
              class="do-action">Següent <mat-icon>arrow_forward</mat-icon></button>
          </div>
        </mat-step>
        <mat-step [stepControl]="memberForm">
          <ng-template matStepLabel>Dades de la quota</ng-template>
          <ng-container *ngTemplateOutlet="step2"></ng-container>
          <div class="button-row">
            <button mat-button mat-raised-button color="accent" matStepperPrevious type="button"
              class="do-action spaced-button"><mat-icon>arrow_back</mat-icon> Enrera</button>
            <button mat-button mat-raised-button color="primary" matStepperNext type="button"
              class="do-action">Següent <mat-icon>arrow_forward</mat-icon></button>
          </div>
        </mat-step>
        <mat-step [stepControl]="memberForm">
          <ng-template matStepLabel>Dades de contacte</ng-template>
          <ng-container *ngTemplateOutlet="step3"></ng-container>
          <div class="button-row">
            <button mat-button mat-raised-button color="accent" matStepperPrevious type="button"
              class="do-action spaced-button"><mat-icon>arrow_back</mat-icon> Enrera</button>
            <button mat-button mat-raised-button color="primary" matStepperNext type="button"
              class="do-action">Següent <mat-icon>arrow_forward</mat-icon></button>
          </div>
        </mat-step>
        <mat-step [stepControl]="memberForm">
          <ng-template matStepLabel>Observacions</ng-template>
          <ng-container *ngTemplateOutlet="step4"></ng-container>
          <div class="button-row">
            <button mat-button mat-raised-button color="accent" matStepperPrevious type="button"
              class="spaced-button"><mat-icon>arrow_back</mat-icon> Enrera</button>
            <button mat-button mat-raised-button color="primary" type="button"
              matTooltip="Verifica al fòrum el nick i l'email" (click)="onVerifyData()"
              [disabled]="!memberForm.get('nick').valid || !memberForm.get('email').valid" class="spaced-button">
              <mat-icon>verified_user</mat-icon> Verifica
            </button>
            <button mat-button mat-raised-button color="primary" matTooltip="Guarda els canvis" type="submit"
              [disabled]="!memberForm.valid ||!authService.hasAnyRole([roles.ADMIN,roles.SECRETARIA])">
              <mat-icon>save</mat-icon> Desa
            </button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </ng-template>

    <ng-template [ngIf]="smallScreen" [ngIfElse]="bigScreen">
      <mat-vertical-stepper [linear]="isLinear" labelPosition="right" #stepper>
        <mat-step [stepControl]="memberForm">
          <ng-template matStepLabel>Dades bàsiques</ng-template>
          <ng-container *ngTemplateOutlet="step1"></ng-container>
        </mat-step>
        <mat-step [stepControl]="memberForm">
          <ng-template matStepLabel>Dades de la quota</ng-template>
          <ng-container *ngTemplateOutlet="step2"></ng-container>
        </mat-step>
        <mat-step [stepControl]="memberForm">
          <ng-template matStepLabel>Dades de contacte</ng-template>
          <ng-container *ngTemplateOutlet="step3"></ng-container>
        </mat-step>
        <mat-step [stepControl]="memberForm">
          <ng-template matStepLabel>Observacions</ng-template>
          <ng-container *ngTemplateOutlet="step4"></ng-container>
          <div class="button-row">
            <button mat-button mat-raised-button color="primary" type="button"
              matTooltip="Verifica al fòrum el nick i l'email" (click)="onVerifyData()"
              [disabled]="!memberForm.get('nick').valid || !memberForm.get('email').valid" class="spaced-button">
              <mat-icon>verified_user</mat-icon> Verifica
            </button>
            <button mat-button mat-raised-button color="primary" matTooltip="Desa els canvis" type="submit"
            [disabled]="!memberForm.valid ||!authService.hasAnyRole([roles.ADMIN,roles.SECRETARIA])">
              <mat-icon>save</mat-icon> Desa
            </button>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </ng-template>
  </form>
</mat-card>
<!-- </div> -->